import { GetServerSideProps, NextPage } from 'next'
import { PageLayout } from '../components/layouts/PageLayout'
import { ParsedUrlQuery } from 'querystring'
import Error from 'next/error'
import { getPageBySlug } from '../lib/contentful'
import { Page as PageEntry } from '../lib/contentful/entry-types'
import { PageTitle, Heading2 } from '../components/styled/titles'
import { Blockquote } from '../components/styled/misc'
import {
  documentToReactComponents,
  NodeRenderer,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

interface PageProps {
  pageData?: PageEntry | null
}

const Page: NextPage<PageProps> = ({ pageData }) => {
  if (!pageData) {
    return <Error statusCode={404} />
  }

  const renderH2: NodeRenderer = (node, children) => {
    return <Heading2 bordered>{children}</Heading2>
  }

  const renderQuote: NodeRenderer = (node, children) => {
    return <Blockquote>{children}</Blockquote>
  }

  const options: Options = {
    renderNode: { [BLOCKS.HEADING_2]: renderH2, [BLOCKS.QUOTE]: renderQuote },
  }

  return (
    <PageLayout>
      <PageTitle>{pageData.title}</PageTitle>
      <div className="page-content">
        {documentToReactComponents(pageData.content, options)}
      </div>
    </PageLayout>
  )
}

interface QueryParams extends ParsedUrlQuery {
  pageSlug: string
}

export const getServerSideProps: GetServerSideProps<
  PageProps,
  QueryParams
> = async ({ params }) => {
  const pageSlug = params?.pageSlug

  if (!pageSlug) {
    return { props: { pageData: undefined } }
  }

  const pageData = await getPageBySlug(pageSlug)

  return { props: { pageData } }
}

export default Page
